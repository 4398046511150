import { getStore } from "app/configureStore";

export const PRODUCT_EXPIRATION_DAY_LIMIT = 30;

export const IS_DEFAULT_LISTING_VIEW_LIST = false;

export const shouldGetProfileAtPayment = () => {
	const state = getStore().getState();
	return state.auth.tokenFromPartner;
};

export const getProfileApiUrl = () => {
	return "https://sp2x6dndpc.execute-api.eu-west-1.amazonaws.com/live/member";
};

export const FIRST_MERCHANDISING_POSITION = 0;
export const SECOND_MERCHANDISING_POSITION = 3;
export const THIRD_MERCHANDISING_POSITION = 7;
export const FOURTH_MERCHANDISING_POSITION = 11;

export const OAUTH_PARAMS = {};
